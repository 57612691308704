import styled from 'styled-components';

export const Wrapper = styled.div<{ $active?: boolean; }>`
  position: relative;
  width: 100%;

  > textarea {
    padding-right: 34px;
  }

  ${({ $active }) => $active && `
    & button {
      opacity: 1;
      visibility: visible;
    }
  `}
`;

export const Toggle = styled.span<{ $size?: 'sm'; $isVisible?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid var(--primary-500-main, #1271A6);
  background-color: transparent;
  position: absolute;
  right: 12px;
  top: 9px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity .15s ease-in-out, visibility .15s ease-in-out;

  ${({ $size }) => $size && $size === 'sm' && `
    top: 4px;
    right: 6px;
  `}

  @media screen and (max-width: 743px) {
    right: 25px;
    ${Wrapper} > div:focus {
      opacity: 1;
      visibility: visible;
    }
    ${({ $isVisible }) => $isVisible && $isVisible === true && `
      opacity: 1;
      visibility: visible;
  `}
    
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    ${Wrapper} > div:focus {
      opacity: 1;
      visibility: visible;
    }
    ${({ $isVisible }) => $isVisible && $isVisible === true && `
      opacity: 1;
      visibility: visible;
    `}
  }

  ${Wrapper} > div:focus + &,
  ${Wrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export const FakeInput = styled.div`
  padding: 8px 36px 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
  color: var(--neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: 1px solid var(--neutral-200, #E5E5E5);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;
